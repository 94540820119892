import React from "react"
import { useState } from "react"
import { Radio, RadioGroup } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/20/solid"

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
]
const tiers = [
  {
    name: "Jet Ski 10min",
    id: "tier-hobby",
    href: "00385 91 112 2241",
    price: { monthly: "40,00 €", annually: "$144" },
    description:
      " A quick and thrilling ride to get a taste of the jet ski experience.",
    features: ["5 products", "Up to 1,000 subscribers", "Basic analytics"],
    mostPopular: false,
  },
  {
    name: "Jet Ski 15min",
    id: "tier-freelancer",
    href: "00385 91 112 2241",
    price: { monthly: "55,00 €", annually: "$288" },
    description:
      "An exhilarating session perfect for a burst of fun on the water.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
    mostPopular: true,
  },
  {
    name: "Jet Ski 30min",
    id: "tier-startup",
    href: "00385 91 112 2241",
    price: { monthly: "100,00 €", annually: "$576" },
    description:
      "A longer ride for those looking to explore the waves and feel the speed.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
    mostPopular: false,
  },
  {
    name: "Jet Ski 60min",
    id: "tier-enterprise",
    href: "00385 91 112 2241",
    price: { monthly: "175,00 €", annually: "$864" },
    description:
      " The ultimate jet ski experience for enthusiasts wanting maximum time on the water.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function PricingTable() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="bg-menublue-50 pyb-24 sm:py-32 sm:pt-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-0">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-yellowdark-50 uppercase font-primary-bold">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight  sm:text-5xl text-white font-primary-bold">
            Jet Ski Packages
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-white font-primary-book">
          Discover the thrill of the waves with our exciting jet ski packages,
          designed to suit every level of adventure. Choose the perfect option
          for your next aquatic adventure and experience the ultimate fun on the
          water.
        </p>
        {/* <div className="mt-16 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              {frequencies.map(option => (
                <Radio
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-indigo-600 text-white" : "text-gray-500",
                      "cursor-pointer rounded-full px-2.5 py-1"
                    )
                  }
                >
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div> */}
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map(tier => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-4 ring-yellowdark-50"
                  : "ring-2 ring-white",
                "rounded-3xl p-8 relative bg-white flex flex-col justify-between"
              )}
            >
              {tier.mostPopular && (
                <div className="absolute top-0 bg-yellowdark-50 rounded-b px-2 py-1">
                  <p className="text-xs font-semibold leading-6 text-purplebglight-50 font-primary-bold">
                    Most Popular
                  </p>
                </div>
              )}
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? "text-menublue-50" : "text-gray-900",
                  "text-xl font-semibold leading-8 font-primary-bold"
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 text-sm leading-6 text-gray-600 font-primary-book">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-3xl font-bold tracking-tight text-gray-900 font-primary-bold">
                  {tier.price.monthly}
                </span>
              </p>
              <a
                href="tel:+385911122241"
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-yellowdark-50 text-purplebglight-50 shadow-sm  hover:scale-105 transition-all"
                    : "bg-yellowdark-50 text-purplebglight-50 shadow-sm  hover:scale-105 transition-all",
                  "mt-6 block rounded-md py-2 px-3 text-center  font-primary-bold text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
              >
                Call Us
              </a>
            </div>
          ))}
        </div>
        <div className="flex bg-white flex-col items-start gap-x-8 mt-10 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
          <div className="lg:min-w-0 lg:flex-1">
            <h3 className="text-lg font-semibold leading-8 tracking-tight text-black font-primary-bold">
              Wheel, Banana, Sofa, Twister, Slider
            </h3>
            <p className="mt-1 text-base leading-7 text-gray-600 font-primary-book">
              Ride per person 20,00 € (minimum 4 people for a ride)
            </p>
          </div>
          <a
            href="tel:+385911122241"
            className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-purplebglight-50 bg-yellowdark-50 text-purplebglight-50 shadow-sm hover:scale-105 transition-all font-primary-bold hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Call Us
          </a>
        </div>
      </div>
    </div>
  )
}

import React, { useContext, useEffect } from "react"
import Layout from "../components/layout"
import { BookingForm } from "../context/context"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import HeroSection from "../containers/hero-section-dynamic"
import Instagram from "../containers/instagram"
import ContentBlock from "../containers/content-block"
import Carousel from "../containers/carousel"
import ContactUs from "../containers/contact-us"
import FunYellow from "../images/fun-yellow.png"
import SportActivitiesHero from "../images/sport-activities-hero.png"
import ContentArea from "../dynamic-zone/content-area"
import ZrceLogoSimple from "../images/zrce-logo-simple.png"
import FunPurple from "../images/fun-yellow.png"
import Eat from "../images/eat-eat-eat.png"
import PricingTable from "../containers/PricingTable"
const LaceBeach = ({ data, location }) => {
  const { setBookingForm } = useContext(BookingForm)
  useEffect(() => {
    setBookingForm({ open: false, type: "Rollin Flock", id: "Rollin Flock" })
  }, [])
  console.log(data.strapiBeach.MobileHeroSection.image)
  return (
    <Layout
      location={location}
      // title={siteTitle}
      logo={ZrceLogoSimple}
      color="bg-menublue-50"
      ctaColor="bg-menublue-50"
      textCtaColor="text-white"
      onlyMainLogoSimple
      onlyContactUs
      // formData={formData}
    >
      <Seo title="Jet Ski Zrce Beach" />
      <HeroSection
        image={
          data.strapiBeach.HeroSectionRestaurants.backgroundImageRestaurant
        }
        sectionType={"restaurant"}
        smallImage={data.strapiBeach.MobileHeroSection.image}
      />
      {/* <ContentBlock title="SPORT ACTIVITIES" color="bg-menublue-50" /> */}
      {/* <Carousel carouselTopImage={FunYellow} color="bg-menublue-50" /> */}
      <ContentArea
        carouselTopImage={FunPurple}
        color="bg-menublue-50"
        contentbLockCtaColor="bg-yellowdark-50"
        contentTypes={data.strapiBeach.pageContent}
        title={data.strapiBeach.title}
        onlyContactUs
        // instaNodes={data.allInstaNode.edges}
      />
      <PricingTable />
      <ContactUs
        color="bg-menublue-50"
        contactHeaderColor="text-white"
        contactSubHeaderColor="text-white"
        contactContentColor="text-white"
        contactCtaColor="text-white"
        contactCtaBorderColor="border-white"
        onlyContactUs
      />
      {/* <ZrceFun />
      <TwoColumnImages />
      <Restaurants />
      <Cabanas />
      <JetSki /> */}
      {/* <LatestNews /> */}
      {/* <Gallery /> */}
      {/* <Instagram bgColor="#80D361" title={"zrcebeach_official"} /> */}
    </Layout>
  )
}

export default LaceBeach

export const pageQuery = graphql`
  query zrceBeachSecond {
    strapiBeach(strapi_id: { eq: 1 }) {
      id
      title
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CAROUSEL {
          id
          cloudText
          strapi_component
          RestaurantCarouselImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CONTENT_BLOCK {
          id
          strapi_component
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
      HeroSectionRestaurants {
        altTag
        backgroundImageRestaurant {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      MobileHeroSection {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`
